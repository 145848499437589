import store from 'storejs'
import { ComponentOptions } from 'vue'

const STORAGE_KEY = 'motorcortex.io.consent'

export default {
  data: () => {
    return {
      hasClientConsented: false,
      showConsentNotice: false,
    }
  },
  methods: {
    registerConsent(consent: Boolean) {
      store.set(STORAGE_KEY, consent)
      this.hasClientConsented = consent

      if (!consent) {
        this.showConsentNotice = false
      }

      if (this.hasClientConsented) {
        this.loadScripts()
      }
    },
    loadScripts() {
      const GAKey = 'UA-208573144-1'

      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.setAttribute('async', 'true')
      script.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${GAKey}`)
      document.body.appendChild(script)

      script.addEventListener('load', () => {
        const w = window as any
        w.dataLayer = w.dataLayer || []

        function gtag() {
          w.dataLayer.push(arguments)
        }

        // @ts-ignore
        gtag('js', new Date())
        // @ts-ignore
        gtag('config', GAKey)
      })

      this.showConsentNotice = false
    },
  },
  mounted() {
    if (!store.has(STORAGE_KEY)) {
      this.showConsentNotice = true
    }

    if (store.get(STORAGE_KEY) === true) {
      this.loadScripts()
    }
  },
} as ComponentOptions
