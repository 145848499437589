import { ComponentOptions } from 'vue'

export default {
  data: () => {
    return {
      isNavigationVisible: false,
    }
  },
  methods: {
    toggleNavigation() {
      this.isNavigationVisible = !this.isNavigationVisible

      document.body.classList.toggle('no-scroll')
    },
  },
} as ComponentOptions
