import { createI18n } from 'vue-i18n'

import translations from '/@front/locale/en.json'

export type MessageSchema = typeof translations

const i18n = createI18n({
  locale: 'en',
  // messages: { en: translations as MessageSchema },
})

export default i18n

const $t = i18n.global.t
export { $t, i18n }
